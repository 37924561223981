import React, {useState} from 'react';
import FsLightbox from "fslightbox-react";

const Video = ({posterSrc, source}) => {

	const [toggler, setToggler] = useState(false);
    return (
        <>
          <div onClick={() => setToggler(!toggler)}>
          <img src={posterSrc} alt="poster" />
          </div>
          <FsLightbox
            toggler={toggler}
            sources={[
               source 
            ]}
          />
        </>
    );
};

export default Video;