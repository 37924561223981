import React, { useState, useEffect } from "react";
import cn from "classnames";
import { FaInstagram, FaLinkedinIn, FaDribbble } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import WebDevelopmentProjects from "./WebDevelopmentProjects";

const social = [
  {
    path: "https://www.instagram.com/idealrahi/",
    icon: <FaInstagram />,
  },
  {
    path: "https://www.linkedin.com/in/md-deluwar-rahi-8b6166220/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://dribbble.com/idealrahi",
    icon: <FaDribbble />,
  },
];

const sampleCategory = [
  {
    title: "UI UX Design",
    path: "/ui-ux-design",
  },
  {
    title: "SaaS Design",
    path: "/saas-design",
  },
  {
    title: "Motion Graphics",
    path: "/motion-graphics",
  },
  {
    title: "Web Development",
    path: "/web-development",
  },
];

const WebDevelopment = ({ activeIndex, setActiveIndex, setShowWorks, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeHamburger, setActiveHamburger] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const portfolio = document.getElementById("portfolio");
      const letsTalk = document.getElementById("contact");
      
      if (portfolio && letsTalk) {
        const portfolioTop = portfolio.offsetTop;
        const portfolioHeight = portfolio.offsetHeight;
        const letsTalkHeight = letsTalk.offsetHeight;
        const portfolioTopHeight =
          portfolioTop + portfolioHeight + letsTalkHeight - window.innerHeight;
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= portfolioTop && winScroll < portfolioTopHeight) {
          isVisible && setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);
  return (
    <section className="portfolio-section" id="portfolio">
      <div className="sticky-nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sticky-wrap">
                <a
                  onClick={() => setActiveHamburger(!activeHamburger)}
                  className={cn("hamburger", { ["active"]: activeHamburger })}
                  role="button"
                >
                  <span></span>
                </a>
                <a href="#contact" className="btn target-selector talk-btn">
                  <span>Let's Talk</span>
                  <FiArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={cn("mbl-menu-wrap", { ["active"]: activeHamburger })}>
          <div className="menu d-flex flex-column align-items-center">
            <NavLink to={"/"} className="target-selector">
              Home.
            </NavLink>
            <NavLink to={"/all-works"} className="target-selector">
              Works.
            </NavLink>
            <HashLink smooth to={"/#services"} className="target-selector">
              Services.
            </HashLink>
            <HashLink smooth to={"/#about"} className="target-selector">
              About.
            </HashLink>
          </div>
        </div>
      </div>
            <div className="portfolio-main">
              <div className="container-fluid px-0">
                <div className="row mx-0">
                  <div className="col-12 text-center my-5">
                    <h2 className="portfolio-title">Rahi.WorkStudio</h2>
                  </div>
                </div>
                <div className="portfolio-menu">
                  <nav className="controls">
                    {sampleCategory.map((x, index) => (
                      <div
                        className={cn("category-item", {
                          active: index + 1 === activeIndex,
                        })}
                        key={index}
                      >
                        <NavLink to={x.path}>{x.title}</NavLink>
                      </div>
                    ))}
                  </nav>
                </div>
                <WebDevelopmentProjects />
              </div>
            </div>
    </section>
  );
};

export default WebDevelopment;