import React, { useRef } from "react";
import { FaInstagram, FaLinkedinIn, FaDribbble } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiFiverr } from "react-icons/si";
import { FiArrowRight } from "react-icons/fi";
import { BsArrowDown } from "react-icons/bs";
import { IoMdQuote } from "react-icons/io";
import VideoJS from "./VideoJs";
import { NavLink } from "react-router-dom";

const socialIconsOne = [
  {
    path: "https://www.instagram.com/idealrahi/",
    icon: <FaInstagram />,
  },
  {
    path: "https://www.linkedin.com/in/idealrahi",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://dribbble.com/idealrahi",
    icon: <FaDribbble />,
  }
];

const socialIconsTwo = [
  {
    path: "https://fiverr.com/idealuix",
    icon: <SiFiverr />,
  },
  {
    path: "https://x.com/idealrahi",
    icon: <FaXTwitter />,
  },
];

const Hero = () => {
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    sources: [
      {
        src: "/video/hero-video.mp4",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <div>
      <div className="hero">
        <div className="mbl-screen d-lg-none">
          <div className="top pt-4">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="logo">
                      <img src="/img/idealrahi UI UX Designer.svg" alt="logo" />
                    </div>
                    <a href="#contact" className="btn target-selector talk-btn">
                      <span>Let's Talk</span>
                      <FiArrowRight />
                    </a>
                  </div>
                  <div className="video mt-4">
                    <VideoJS
                      options={videoJsOptions}
                      onReady={handlePlayerReady}
                    />
                  </div> 
                  <div className="menu">
                    <NavLink to={"/"} className="target-selector" >Home.</NavLink>
                    <NavLink to={"/all-works"} className="target-selector">Works.</NavLink>
                    <a className="target-selector" href="#services">Services.</a>
                    <a className="target-selector" href="#about">About.</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="d-flex">
                    <div className="pic">
                      <img src="/img/hero/pic.png" alt="pic" />
                    </div>
                    <div className="skills-wrap">
                      <h4>Skills</h4>
                      <div className="skills">
                        <h1>UI UX Design</h1>
                        <h1>Web Development</h1>
                        <h1>SaaS Design</h1>
                      </div>
                      <div className="time-frame">
                        <p>
                          <span>2016 to</span>Present
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-wrap d-flex">
                    <a href="#works" className="scroll-down">
                      <div className="scroll-txt">
                        <p>
                          Scroll <br /> down
                        </p>
                        <BsArrowDown />
                      </div>
                    </a>
                    <div className="name">
                      <h1>
                        Deluwar <span>Rahi</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="large-screen d-none d-lg-block">
          <div className="bg-wrap">
            <div className="left"></div>
            <div className="right"></div>
          </div>
          <div className="hero-wrap">
            <div className="container">
              <div className="row g-0">
                <div className="col-lg-4">
                  <div className="left d-flex justify-content-end">
                    <div className="wrap d-flex flex-column justify-content-between py-5 pe-5">
                      <div className="logo">
                        <img src="/img/idealrahi UI UX Designer.svg" alt="logo" />
                      </div>
                      <div className="skills-wrap">
                        <h4>Skills</h4>
                        <div className="skills">
                          <h1>UI UX Design</h1>
                          <h1>SaaS Design</h1>
                          <h1>Web Development</h1>
                          
                        </div>
                      </div>
                      <div className="pic-wrap">
                        <div className="d-flex align-items-center gap-4">
                          <div className="pic">
                            <img className="pic-one" src="/img/hero/pic.png" alt="pic" />
                            <img className="pic-two" src="/img/hero/rahi-2.webp" alt="pic" />
                          </div>
                          <div className="icons-wrap">
                          <div className="icons">
                            {socialIconsOne.map((x, index) => (
                              <a key={index} href={x.path} target="_blank" rel="noreferrer">
                            
                                {x.icon}
                              </a>
                            ))}
                          </div>
                          <div className="icons">
                            {socialIconsTwo.map((x, index) => (
                              <a className={x.icon?.type?.name === "SiFiverr" ? "icons-fiverr" : ''} key={index} href={x.path} target="_blank" rel="noreferrer">
                                {x.icon}
                              </a>
                            ))}
                          </div>
                          </div>
                        </div>
                        <div className="scroll-wrap">
                          <p>Say Hello, Tell me things</p>
                          <div className="scroll d-flex">
                            <a href="#works" className="scroll-down">
                              <p>
                                Scroll <br /> down
                              </p>
                              <span>
                                <BsArrowDown />
                              </span>
                            </a>
                            <div className="quote">
                              <IoMdQuote />
                            </div>
                            <div className="line"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="hero-details pt-5 pb-3 ps-5">
                    <div className="top">
                      <div className="wrap d-flex justify-content-between">
                      <div className="menu">
                        <NavLink to={"/all-works"} className="link" >Works.</NavLink>
                        <a className="link" href="./#services">Services.</a>
                        <a className="link" href="./#about">About.</a>
                        <a className="link" href="./#contact">Contact Me.</a>
                      </div>
                        <a href="https://calendly.com/idealrahi/30min?back=1" target="_blank" rel="noreferrer" className="btn talk-btn">
                          <span>Book A Call</span>
                          <FiArrowRight />
                        </a>
                      </div>
                      <div className="video ps-5 mt-4">
                        <VideoJS
                          options={videoJsOptions}
                          onReady={handlePlayerReady}
                        />
                      </div>
                    </div>
                    <div className="bottom ps-5">
                      <div className="time-frame">
                        <p>
                          <span>2016 to</span>Present
                        </p>
                      </div>
                        <div className="name">
                          <div className="line"></div>
                          <h1>
                            deluwar <br /> <span>Rahi</span>
                          </h1>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
