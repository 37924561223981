import "./scss/style.scss";
import { useState, useEffect } from "react";
import { ClimbingBoxLoader } from "react-spinners";
import React from "react";
import WhatsAppButton from "./components/WhatsAppButton";
import AnimatedCursor from "react-animated-cursor";
import Routing from "./pages/Routing";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <div className="bodyLoaderWrap">
      <ClimbingBoxLoader color="#FFE500" />
    </div>
  ) : (
    <>
      <Routing />
      <WhatsAppButton />
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="0, 0, 0"
        outerStyle={{
          border: "3px solid rgb(0, 0, 0)",
        }}
        innerStyle={{
          backgroundColor: "rgb(0, 0, 0)",
        }}
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
        showSystemCursor={true}
      />
    </>
  );
}

export default App;
