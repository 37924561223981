import React from "react";
import { FaInstagram, FaLinkedinIn, FaDribbble } from "react-icons/fa";

const social = [
  {
    path: "https://www.instagram.com/idealrahi/",
    icon: <FaInstagram />,
  },
  {
    path: "https://www.linkedin.com/in/md-deluwar-rahi-8b6166220/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://dribbble.com/idealrahi",
    icon: <FaDribbble />,
  },
];

const RightSideSocial = () => {
  return (
    <div className="right-side-social position-fixed">
      {social.map((x, index) => (
        <a key={index} href={x.path}>{x.icon}</a>
      ))}
    </div>
  );
};

export default RightSideSocial;
